import Box from "@mui/material/Box";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { HEADER_HEIGHT } from "../../../../../components/layout/global-header";
import { Loading } from "../../../../../components/loading/loading";
import { Chart } from "../../../../../types";
import { BaseCalcBasisProps } from "../../../classes/base-calc-basis";
import { BaseChartProps } from "../../../classes/base-chart";
import { AgeDistributionByGenderChart } from "../../../classes/column/binary-gender-type/age-distribution-by-gender/age-distribution-by-gender-chart";
import { AverageChildcareLeaveTakenDaysByGenderChart } from "../../../classes/column/binary-gender-type/average-childcare-leave-taken-days-by-gender/average-childcare-leave-taken-days-by-gender-chart";
import { BaseBinaryGenderTypeColumnData } from "../../../classes/column/binary-gender-type/base-binary-gender-type-column-chart";
import { DisabledEmploymentShortageHeadcountChart } from "../../../classes/column/disabled-employment-shortage-headcount/disabled-employment-shortage-headcount-chart";
import { AverageServiceYearByGenderChart } from "../../../classes/column/gender-type/average-service-year-by-gender/average-service-year-by-gender-chart";
import { BaseGenderTypeColumnData } from "../../../classes/column/gender-type/base-gender-type-column-chart";
import { RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart } from "../../../classes/column/regular-employee-average-annual-hours-over-scheduled-working-hours/regular-employee-average-annual-hours-over-scheduled-working-hours-chart";
import { RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart } from "../../../classes/column/regular-employee-average-monthly-hours-over-scheduled-working-hours/regular-employee-average-monthly-hours-over-scheduled-working-hours-chart";
import { AverageAnnualPaidLeaveConsumedDaysAndRateChart } from "../../../classes/column-line/days-and-rate/average-annual-paid-leave-consumed-days-and-rate/average-annual-paid-leave-consumed-days-and-rate-chart";
import { BaseDaysAndRateTypeColumnLineData } from "../../../classes/column-line/days-and-rate/base-days-and-rate-type-column-line-chart";
import { BaseHeadcountAndRateTypeColumnLineData } from "../../../classes/column-line/headcount-and-rate-type/base-headcount-and-rate-type-column-line-chart";
import { ForeignEmployeeHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-employee-headcount-and-rate/foreign-employee-headcount-and-rate-chart";
import { ForeignExecutiveHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-executive-headcount-and-rate/foreign-executive-headcount-and-rate-chart";
import { ForeignNewGraduateEmployedHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-new-graduate-employed-headcount-and-rate/foreign-new-graduate-employed-headcount-and-rate-chart";
import { MidCareerEmployedExecutiveHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/mid-career-employed-executive-headcount-and-rate/mid-career-employed-executive-headcount-and-rate-chart";
import { MidCareerEmployedHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/mid-career-employed-headcount-and-rate/mid-career-employed-headcount-and-rate-chart";
import { BaseHeadcountAndRatioTypeColumnLineData } from "../../../classes/column-line/headcount-and-ratio-type/base-headcount-and-ratio-type-column-line-chart";
import { MaleChildcareLeaveTakenDayDistributionChart } from "../../../classes/column-line/headcount-and-ratio-type/male-childcare-leave-taken-day-distribution/male-childcare-leave-taken-day-distribution-chart";
import { BaseEmployeeTypeLineData } from "../../../classes/line/employee-type/base-employee-type-line-chart";
import {
  GenderPayGapCalcBasis,
  GenderPayGapCalcBasisData,
} from "../../../classes/line/employee-type/gender-pay-gap/gender-pay-gap-calc-basis";
import { GenderPayGapChart } from "../../../classes/line/employee-type/gender-pay-gap/gender-pay-gap-chart";
import { MaleChildcareLeaveRateByEmployeeTypeChart } from "../../../classes/line/employee-type/male-childcare-leave-rate-by-employee-type/male-childcare-leave-rate-by-employee-type-chart";
import { FemaleManagerRatioCalcBasis } from "../../../classes/line/female-manager-ratio/female-manager-ratio-calc-basis";
import { FemaleManagerRatioChart } from "../../../classes/line/female-manager-ratio/female-manager-ratio-chart";
import { GenderAverageServiceYearGapChart } from "../../../classes/line/gender-average-service-year-gap/gender-average-service-year-gap-chart";
import { AnnualEmployedXYearRetentionRateByGenderChart } from "../../../classes/line/gender-type/annual-employed-x-year-retention-rate-by-gender/annual-employed-x-year-retention-rate-by-gender-chart";
import { AverageAgeByGenderChart } from "../../../classes/line/gender-type/average-age-by-gender/average-age-by-gender-chart";
import { AverageAnnualBonusByGenderChart } from "../../../classes/line/gender-type/average-annual-bonus-by-gender/average-annual-bonus-by-gender-chart";
import { AverageAnnualSalaryByGenderChart } from "../../../classes/line/gender-type/average-annual-salary-by-gender/average-annual-salary-by-gender-chart";
import { BaseGenderTypeLineData } from "../../../classes/line/gender-type/base-gender-type-line-chart";
import { ChildcareLeaveRateByGenderChart } from "../../../classes/line/gender-type/childcare-leave-rate-by-gender/childcare-leave-rate-by-gender-chart";
import { AverageAnnualBonusByGradeTypeChart } from "../../../classes/line/grade-type/average-annual-bonus-by-grade-type/average-annual-bonus-by-grade-type-chart";
import { BaseGradeTypeLineData } from "../../../classes/line/grade-type/base-grade-type-line-chart";
import { MaleChildcareLeaveOrChildcarePurposeLeaveRateChart } from "../../../classes/line/male-childcare-leave-or-childcare-purpose-leave-rate/male-childcare-leave-or-childcare-purpose-leave-rate-chart";
import { MaleChildcareLeaveRateCalcBasis } from "../../../classes/line/male-childcare-leave-rate/male-childcare-leave-rate-calc-basis";
import { MaleChildcareLeaveRateChart } from "../../../classes/line/male-childcare-leave-rate/male-childcare-leave-rate-chart";
import { BaseManagementTypeLineData } from "../../../classes/line/management-type/base-management-type-line-chart";
import { SalaryIncreaseRateByManagementTypeChart } from "../../../classes/line/management-type/salary-increase-rate-by-management-type/salary-increase-rate-by-management-type-chart";
import { BaseDisciplineTypeStackedColumnData } from "../../../classes/stacked-column/discipline-type/base-discipline-type-stacked-column-chart";
import { DisciplineCountByDisciplineTypeChart } from "../../../classes/stacked-column/discipline-type/discipline-count-by-discipline-type/discipline-count-by-discipline-type-chart";
import { BaseEmployeeTypeStackedColumnData } from "../../../classes/stacked-column/employee-type/base-employee-type-stacked-column-chart";
import { FullTimeEmployeeHeadcountByEmployeeTypeChart } from "../../../classes/stacked-column/employee-type/full-time-employee-headcount-by-employee-type/full-time-employee-headcount-by-employee-type-chart";
import { BaseGenderTypeStackedColumnData } from "../../../classes/stacked-column/gender-type/base-gender-type-stacked-column-chart";
import { ForeignEmployeeHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/foreign-employee-headcount-by-gender/foreign-employee-headcount-by-gender-chart";
import { FullTimeEmployeeHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/full-time-employee-headcount-by-gender/full-time-employee-headcount-by-gender-chart";
import { BaseNationalityTypeStackedColumnData } from "../../../classes/stacked-column/nationality-type/base-nationality-type-stacked-column-chart";
import { ForeignEmployeeHeadcountByNationalityChart } from "../../../classes/stacked-column/nationality-type/foreign-employee-headcount-by-nationality/foreign-employee-headcount-by-nationality-chart";
import { AnnualEmployedCompositionByGenderChart } from "../../../classes/stacked-column-line/binary-gender-composition-type/annual-employed-composition-by-gender/annual-employed-composition-by-gender-chart";
import { BaseBinaryGenderCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/binary-gender-composition-type/base-binary-gender-composition-type-stacked-column-line-chart";
import { ExecutiveGenderCompositionChart } from "../../../classes/stacked-column-line/binary-gender-composition-type/executive-gender-composition/executive-gender-composition-chart";
import { BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData } from "../../../classes/stacked-column-line/disabled-employment-headcount-and-rate-type/base-disabled-employment-headcount-and-rate-type-stacked-column-line-chart";
import { DisabledEmploymentHeadcountAndRateChart } from "../../../classes/stacked-column-line/disabled-employment-headcount-and-rate-type/disabled-employment-headcount-and-rate/disabled-employment-headcount-and-rate-chart";
import { AnnualEmployedCompositionByEmploymentTypeChart } from "../../../classes/stacked-column-line/employment-composition-type/annual-employed-composition-by-employment-type/annual-employed-composition-by-employment-type-chart";
import { BaseEmploymentCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/employment-composition-type/base-employment-composition-type-stacked-column-line-chart";
import { BaseGenderCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/gender-composition-type/base-gender-composition-type-stacked-column-line-chart";
import { GenderCompositionChart } from "../../../classes/stacked-column-line/gender-composition-type/gender-composition/gender-composition-chart";
import { BaseGenderHeadcountAndRateTypeStackedColumnLineData } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/base-gender-headcount-and-rate-type-stacked-column-line-chart";
import { SeparatedHeadcountAndRateByGenderChart } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/separated-headcount-and-rate-by-gender/separated-headcount-and-rate-by-gender-chart";
import { DashboardCalcBasis } from "../../../components/dashboard-calc-basis";
import { DashboardChart } from "../../../components/dashboard-chart";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import {
  DashboardChartFilterForm,
  DashboardChartFilterFormModel,
} from "../../../components/dashboard-chart-filter-form";
import { CHART_HEADER_HEIGHT } from "../../../components/layout/dashboard-chart-header";
import { DashboardChartLayout } from "../../../components/layout/dashboard-chart-layout";
import {
  CalcBasisNotesTextDef,
  CalcBasisUnitCaptionTextDef,
  ChartNotesTextDef,
  ChartUnitCaptionTextDef,
} from "../../../config/text-def";
import { DashboardChartGetRequest } from "../../../dashboard-api";
import { DashboardStore } from "../../../stores/dashboard-store";
import { BaseData, CalcBasisBaseData } from "../../../types";

const CHARTS_HAVING_CALC_BASIS = ["gender_pay_gap", "female_manager_ratio", "male_childcare_leave_rate"];
export const DashboardChartIndex = () => {
  const { t } = useTranslation();
  const { id: chart } = useParams() as { id: Chart };
  const [, setSearchParams] = useSearchParams();

  const { selectedChartType } = DashboardStore.useContainer();
  const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false);
  const [isChartLoading, setIsChartLoading] = useState<boolean>(true);
  const [isCalcBasisLoading, setIsCalcBasisLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<DashboardChartGetRequest>();
  const [displaySwitch, setDisplaySwitch] = useState<DashboardChartDisplaySwitchModel>({
    selectedDisplayValues: [],
    showIndustryAverage: false,
    showAllIndustryAverage: false,
    showDataLabel: true,
  });

  const onSubmit = ({
    fromBusinessYearStartDate,
    toBusinessYearStartDate,
    companyType,
    companyIds,
    industryDivision,
  }: DashboardChartFilterFormModel) => {
    setIsChartLoading(true);
    setFilter({
      fromBusinessYearStartDate,
      toBusinessYearStartDate,
      companyType,
      companyIds,
      industryDivision,
    });
    setSearchParams({
      fromBusinessYearStartDate: fromBusinessYearStartDate,
      toBusinessYearStartDate: toBusinessYearStartDate,
      companyType: companyType,
      companyIds: companyIds.join(","),
      industryDivision: industryDivision,
    });
  };

  useEffect(() => {
    // 算出根拠が実装されていないchartの場合に読み込みが完了しないのを回避するため
    if (!CHARTS_HAVING_CALC_BASIS.includes(chart)) {
      setIsCalcBasisLoading(false);
    }
  }, [chart]);

  useEffect(() => {
    if (!isChartLoading && !isCalcBasisLoading) {
      setIsAllLoaded(true);
    } else {
      setIsAllLoaded(false);
    }
  }, [isChartLoading, isCalcBasisLoading]);

  const onChartLoaded = useCallback(() => {
    setIsChartLoading(false);
  }, []);

  const onCalcBasisLoaded = useCallback(() => {
    setIsCalcBasisLoading(false);
  }, []);

  const isSingleYear = (chart: Chart) => {
    return [
      "age_distribution_of_regular_employee_by_gender",
      "age_distribution_of_manager_by_gender",
      "male_childcare_leave_taken_day_distribution",
    ].includes(chart);
  };

  const renderChart = (chart: Chart) => {
    const unitCaption = ChartUnitCaptionTextDef.get(chart) && t(ChartUnitCaptionTextDef.get(chart) as string);
    const notes = ChartNotesTextDef.get(chart)?.map((i18nKey) => t(i18nKey));
    const baseChartProps: BaseChartProps = {
      chart,
      unitCaption,
      notes,
    };
    switch (chart) {
      case "gender_pay_gap":
        return (
          <DashboardChart<BaseEmployeeTypeLineData, GenderPayGapChart>
            chartInstance={GenderPayGapChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "female_manager_ratio":
        return (
          <DashboardChart<BaseData, FemaleManagerRatioChart>
            chartInstance={FemaleManagerRatioChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "male_childcare_leave_rate":
        return (
          <DashboardChart<BaseData, MaleChildcareLeaveRateChart>
            chartInstance={MaleChildcareLeaveRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "male_childcare_leave_rate_by_employee_type":
        return (
          <DashboardChart<BaseEmployeeTypeLineData, MaleChildcareLeaveRateByEmployeeTypeChart>
            chartInstance={MaleChildcareLeaveRateByEmployeeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "male_childcare_leave_or_childcare_purpose_leave_rate":
        return (
          <DashboardChart<BaseData, MaleChildcareLeaveOrChildcarePurposeLeaveRateChart>
            chartInstance={MaleChildcareLeaveOrChildcarePurposeLeaveRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );

      case "gender_composition_of_all_employee":
      case "gender_composition_of_regular_employee":
      case "gender_composition_of_non_regular_employee":
        return (
          <DashboardChart<BaseGenderCompositionTypeStackedColumnLineData, GenderCompositionChart>
            chartInstance={GenderCompositionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "full_time_employee_headcount_by_gender":
        return (
          <DashboardChart<BaseGenderTypeStackedColumnData, FullTimeEmployeeHeadcountByGenderChart>
            chartInstance={FullTimeEmployeeHeadcountByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "full_time_employee_headcount_by_employee_type":
        return (
          <DashboardChart<BaseEmployeeTypeStackedColumnData, FullTimeEmployeeHeadcountByEmployeeTypeChart>
            chartInstance={FullTimeEmployeeHeadcountByEmployeeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "average_age_of_all_employee_by_gender":
      case "average_age_of_regular_employee_by_gender":
        return (
          <DashboardChart<BaseGenderTypeLineData, AverageAgeByGenderChart>
            chartInstance={AverageAgeByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "age_distribution_of_regular_employee_by_gender":
      case "age_distribution_of_manager_by_gender":
        return (
          <DashboardChart<BaseBinaryGenderTypeColumnData, AgeDistributionByGenderChart>
            chartInstance={AgeDistributionByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "average_service_year_of_all_employee_by_gender":
      case "average_service_year_of_regular_employee_by_gender":
      case "average_service_year_of_non_regular_employee_by_gender":
      case "full_time_employee_average_service_year_by_gender":
        return (
          <DashboardChart<BaseGenderTypeColumnData, AverageServiceYearByGenderChart>
            chartInstance={AverageServiceYearByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "gender_average_service_year_gap_of_all_employee":
      case "gender_average_service_year_gap_of_regular_employee":
      case "gender_average_service_year_gap_of_non_regular_employee":
      case "full_time_employee_gender_average_service_year_gap":
        return (
          <DashboardChart<BaseGenderTypeColumnData, GenderAverageServiceYearGapChart>
            chartInstance={GenderAverageServiceYearGapChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "annual_new_graduate_employed_three_year_retention_rate_of_college_graduate_by_gender":
      case "annual_new_graduate_employed_five_year_retention_rate_of_college_graduate_by_gender":
      case "annual_mid_career_employed_three_year_retention_rate_by_gender":
      case "annual_mid_career_employed_five_year_retention_rate_by_gender":
        return (
          <DashboardChart<BaseGenderTypeLineData, AnnualEmployedXYearRetentionRateByGenderChart>
            chartInstance={new AnnualEmployedXYearRetentionRateByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "annual_employed_composition_of_all_employee_by_gender":
      case "annual_employed_composition_of_regular_employee_by_gender":
      case "annual_employed_composition_of_non_regular_employee_by_gender":
      case "annual_new_graduate_employed_composition_of_regular_employee_by_gender":
      case "annual_mid_career_employed_composition_of_regular_employee_by_gender":
        return (
          <DashboardChart<BaseBinaryGenderCompositionTypeStackedColumnLineData, AnnualEmployedCompositionByGenderChart>
            chartInstance={AnnualEmployedCompositionByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "annual_employed_composition_of_regular_employee_by_employment_type":
        return (
          <DashboardChart<
            BaseEmploymentCompositionTypeStackedColumnLineData,
            AnnualEmployedCompositionByEmploymentTypeChart
          >
            chartInstance={AnnualEmployedCompositionByEmploymentTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "mid_career_employed_headcount_and_rate_of_all_employee":
      case "mid_career_employed_headcount_and_rate_of_manager":
        return (
          <DashboardChart<BaseHeadcountAndRateTypeColumnLineData, MidCareerEmployedHeadcountAndRateChart>
            chartInstance={MidCareerEmployedHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "mid_career_employed_executive_headcount_and_rate":
        return (
          <DashboardChart<BaseHeadcountAndRateTypeColumnLineData, MidCareerEmployedExecutiveHeadcountAndRateChart>
            chartInstance={MidCareerEmployedExecutiveHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "separated_headcount_and_rate_of_regular_employee_by_gender":
      case "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender":
        return (
          <DashboardChart<BaseGenderHeadcountAndRateTypeStackedColumnLineData, SeparatedHeadcountAndRateByGenderChart>
            chartInstance={SeparatedHeadcountAndRateByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "executive_gender_composition":
        return (
          <DashboardChart<BaseBinaryGenderCompositionTypeStackedColumnLineData, ExecutiveGenderCompositionChart>
            chartInstance={ExecutiveGenderCompositionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "childcare_leave_rate_of_all_employee_by_gender":
      case "childcare_leave_rate_of_regular_employee_by_gender":
      case "childcare_leave_rate_of_non_regular_employee_by_gender":
        return (
          <DashboardChart<BaseGenderTypeLineData, ChildcareLeaveRateByGenderChart>
            chartInstance={ChildcareLeaveRateByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "average_childcare_leave_taken_days_of_all_employee_by_gender":
      case "average_childcare_leave_taken_days_of_regular_employee_by_gender":
      case "average_childcare_leave_taken_days_of_non_regular_employee_by_gender":
        return (
          <DashboardChart<BaseBinaryGenderTypeColumnData, AverageChildcareLeaveTakenDaysByGenderChart>
            chartInstance={AverageChildcareLeaveTakenDaysByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "male_childcare_leave_taken_day_distribution":
        return (
          <DashboardChart<BaseHeadcountAndRatioTypeColumnLineData, MaleChildcareLeaveTakenDayDistributionChart>
            chartInstance={MaleChildcareLeaveTakenDayDistributionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_employee_headcount_by_nationality":
        return (
          <DashboardChart<BaseNationalityTypeStackedColumnData, ForeignEmployeeHeadcountByNationalityChart>
            chartInstance={ForeignEmployeeHeadcountByNationalityChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_employee_headcount_by_gender":
        return (
          <DashboardChart<BaseGenderTypeStackedColumnData, ForeignEmployeeHeadcountByGenderChart>
            chartInstance={ForeignEmployeeHeadcountByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_employee_headcount_and_rate_of_all_employee":
      case "foreign_employee_headcount_and_rate_of_manager":
        return (
          <DashboardChart<BaseHeadcountAndRateTypeColumnLineData, ForeignEmployeeHeadcountAndRateChart>
            chartInstance={ForeignEmployeeHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_new_graduate_employed_headcount_and_rate":
        return (
          <DashboardChart<BaseHeadcountAndRateTypeColumnLineData, ForeignNewGraduateEmployedHeadcountAndRateChart>
            chartInstance={ForeignNewGraduateEmployedHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_executive_headcount_and_rate":
        return (
          <DashboardChart<BaseHeadcountAndRateTypeColumnLineData, ForeignExecutiveHeadcountAndRateChart>
            chartInstance={ForeignExecutiveHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "disabled_employment_headcount_and_rate":
        return (
          <DashboardChart<
            BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData,
            DisabledEmploymentHeadcountAndRateChart
          >
            chartInstance={DisabledEmploymentHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "disabled_employment_shortage_headcount":
        return (
          <DashboardChart<BaseData, DisabledEmploymentShortageHeadcountChart>
            chartInstance={DisabledEmploymentShortageHeadcountChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee":
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager":
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager":
        return (
          <DashboardChart<BaseData, RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart>
            chartInstance={RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee":
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager":
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager":
        return (
          <DashboardChart<BaseData, RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart>
            chartInstance={RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "average_annual_paid_leave_consumed_days_and_rate_of_all_employee":
      case "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee":
      case "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee":
        return (
          <DashboardChart<BaseDaysAndRateTypeColumnLineData, AverageAnnualPaidLeaveConsumedDaysAndRateChart>
            chartInstance={AverageAnnualPaidLeaveConsumedDaysAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "average_annual_salary_of_regular_employee_by_gender":
        return (
          <DashboardChart<BaseGenderTypeLineData, AverageAnnualSalaryByGenderChart>
            chartInstance={AverageAnnualSalaryByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "average_annual_bonus_of_regular_employee_by_gender":
        return (
          <DashboardChart<BaseGenderTypeLineData, AverageAnnualBonusByGenderChart>
            chartInstance={AverageAnnualBonusByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "average_annual_bonus_of_regular_employee_by_grade_type":
        return (
          <DashboardChart<BaseGradeTypeLineData, AverageAnnualBonusByGradeTypeChart>
            chartInstance={AverageAnnualBonusByGradeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "salary_increase_rate_of_regular_employee_by_management_type":
        return (
          <DashboardChart<BaseManagementTypeLineData, SalaryIncreaseRateByManagementTypeChart>
            chartInstance={SalaryIncreaseRateByManagementTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      case "discipline_count_by_discipline_type":
        return (
          <DashboardChart<BaseDisciplineTypeStackedColumnData, DisciplineCountByDisciplineTypeChart>
            chartInstance={DisciplineCountByDisciplineTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            setDisplaySwitch={setDisplaySwitch}
            isAllLoaded={isAllLoaded}
            onLoaded={onChartLoaded}
          />
        );
      default:
        throw new Error(`Invalid chart: ${chart}`);
    }
  };

  const renderCalcBasis = (chart: Chart) => {
    const unitCaption = CalcBasisUnitCaptionTextDef.get(chart) && t(CalcBasisUnitCaptionTextDef.get(chart) as string);
    const notes = CalcBasisNotesTextDef.get(chart)?.map((i18nKey) => t(i18nKey));
    const baseCalcBasisProps: BaseCalcBasisProps = {
      unitCaption,
      notes,
    };
    switch (chart) {
      case "gender_pay_gap":
        return (
          <Box mb={2}>
            <DashboardCalcBasis<GenderPayGapCalcBasisData, GenderPayGapCalcBasis>
              calcBasisInstance={GenderPayGapCalcBasis.getInstance(baseCalcBasisProps)}
              chart={chart}
              filter={filter}
              isAllLoaded={isAllLoaded}
              onLoaded={onCalcBasisLoaded}
            />
          </Box>
        );
      case "female_manager_ratio":
        return (
          <Box mb={2}>
            <DashboardCalcBasis<CalcBasisBaseData, FemaleManagerRatioCalcBasis>
              calcBasisInstance={FemaleManagerRatioCalcBasis.getInstance(baseCalcBasisProps)}
              chart={chart}
              filter={filter}
              isAllLoaded={isAllLoaded}
              onLoaded={onCalcBasisLoaded}
            />
          </Box>
        );
      case "male_childcare_leave_rate":
        return (
          <Box mb={2}>
            <DashboardCalcBasis<CalcBasisBaseData, MaleChildcareLeaveRateCalcBasis>
              calcBasisInstance={MaleChildcareLeaveRateCalcBasis.getInstance(baseCalcBasisProps)}
              chart={chart}
              filter={filter}
              isAllLoaded={isAllLoaded}
              onLoaded={onCalcBasisLoaded}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <DashboardChartLayout>
      <Box display="flex">
        <DashboardChartFilterForm
          chart={chart}
          applyDisabled={!isAllLoaded}
          isSingleYear={isSingleYear(chart)}
          onSubmit={onSubmit}
        />

        <Box
          overflow="scroll"
          height={`calc(var(--vh, 100vh) - ${HEADER_HEIGHT + CHART_HEADER_HEIGHT}px)`}
          width="100%"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
        >
          <Box p={2}>
            {renderCalcBasis(chart)}
            <Box>{renderChart(chart)}</Box>
          </Box>
          {!isAllLoaded && <Loading />}
        </Box>
      </Box>
    </DashboardChartLayout>
  );
};
